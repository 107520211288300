<template>
    <div class="personAdmin">
        <div class="facilityBox">
            <el-form inline class="searchForm" :model="searchForm" ref="searchForm">
                <el-form-item label="userID" prop="userID" label-width="50px">
                    <el-input v-model="searchForm.userID" placeholder="请输入userID">
                    </el-input>
                </el-form-item>
                <el-form-item label="交易类型" prop="transType" label-width="80px">
                    <el-input v-model="searchForm.transType" placeholder="请输入交易类型（10：充值 20：提现 30：分账）">
                    </el-input>
                </el-form-item>
                <el-form-item label="日期查询" prop="dateTime" label-width="90px">
                    <el-date-picker v-model="searchForm.dateTime" type="daterange" @change="search('searchForm')"
                        value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="search" icon="el-icon-search">搜索
                    </el-button>
                    <el-button type="primary" size="medium" @click="resetForm" icon="el-icon-delete">清空</el-button>
                </el-form-item>
            </el-form>
            <div class="topbtns">
                <el-button
                    icon="el-icon-download"
                    type="primary"
                    size="medium"
                    @click="toExport"
                    :disabled="!tableData.length"
                    >
                    导出表格
                </el-button>
            </div>
            <el-table :data="tableData" ref="table" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                v-loading="loading">
                <el-table-column align="center" type="index" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" width="200" prop="transNo" label="银行侧流水号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" width="200" prop="forAcctNo" label="交易对手账号" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="forAcctName" label="交易对手账户名" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" width="120" prop="forBankName" label="交易对手银行名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" width="100" prop="transType" label="交易类型" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" width="100" prop="crdrFag" label="借贷标识" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" width="100" prop="reverseFlag" label="冲正标识" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" width="150" prop="amount" label="交易金额" show-overflow-tooltip>
                    <template slot-scope="scope">
                        ￥{{ scope.row.amount }}
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="remark" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="transTime" label="交易时间" show-overflow-tooltip></el-table-column>
                <el-table-column align="center" label="操作" fixed="right" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" @click="downloadReceipt(scope.row)"
                            icon="el-icon-download">电子回单
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div style="text-align: left; margin-top: 10px">
                <el-pagination background @current-change="handleCurrentChange" :current-page.sync="pagination.page"
                    :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {
    GetYumiTrans,
    DownloadReceipt,
    DownLoadYumiTrans
} from "@/api/power/person";
export default {
    data() {
        return {
            loading: false,
            pagination: {
                //分页控件相关参数
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            searchForm: {
                transType: "", //交易类型
                dateTime: ["", ""], //时间范围
                userID: ''
            },
            tableData: [], //表格数据
        };
    },
    methods: {
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e
            this.getYumiTrans()
        },
        //搜索
        search() {
            this.pagination.page = 1;
            this.getYumiTrans()
        },
        //清空
        resetForm() {
            this.searchForm.transType = "";
            this.searchForm.userID = "";
            this.searchForm.dateTime = ["", ""];
            this.pagination.page = 1;
            this.getYumiTrans()
        },
        // 获取列表
        getYumiTrans() {
            let params = {
                userID: Number(this.searchForm.userID),
                transType: this.searchForm.transType,
                startDate: this.searchForm.dateTime ? this.searchForm.dateTime[0] : '',
                endDate: this.searchForm.dateTime ? this.searchForm.dateTime[1] : '',
                pageSize: this.pagination.pagesize,
                pageNum: this.pagination.page
            };
            this.loading = true
            GetYumiTrans(params).then((res) => {
                this.tableData = res.data.data;
                this.pagination.total = Number(res.data.totalCount);
                this.loading = false
            }).finally(() => {
                this.loading = false
            })
        },
        downloadReceipt(item) {
            let params = {
                date: item.transTime.substring(0, 10),
                transNo: item.transNo
            }
            DownloadReceipt(params).then(res => {
                window.open(res.data, '_blank')
            })
        },

        // 导出表格
        toExport() {
        let params = {
                userID: Number(this.searchForm.userID),
                transType: this.searchForm.transType,
                startDate: this.searchForm.dateTime ? this.searchForm.dateTime[0] : '',
                endDate: this.searchForm.dateTime ? this.searchForm.dateTime[1] : '',
                pageSize: this.pagination.pagesize,
                pageNum: this.pagination.page
            };
        DownLoadYumiTrans(params).then(res=>{
            window.open(res.data, '_blank')
            })
        },
    },
    created() {
        // this.getYumiTrans()
    },
};
</script>
<style lang="scss" scoped>
.topbtns {
    padding-bottom: 10px;
    margin: 10px 0px;
    border-bottom: 1px solid #eee;
  }
</style>